import React from 'react'
import '../styles/Elipses.css'

export default function Elipses() {
  return (
    <div className="elipses">
        <div className="elipse1"></div>
        <div className="elipse2"></div>
    </div>
  )
}
